import request from "./http";

export const getTaskInfo = async (params, project_name) => await request({
    method: 'get',
    url: `/task/${project_name}`,
    params: params
})
export const getTaskBotURL = async (params, project_name) => await request({
    method: 'get',
    url: `/task_bot_url/${project_name}`,
    params: params
})